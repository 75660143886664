export const portalLogin = `https://portal.musiceternal.life/login`;

export const socials = {
  facebook: `https://www.facebook.com/MusicEternal`,
  instagram: `https://www.instagram.com/musiceternal.life/`,
  x: `https://x.com/MusicEternal_AU`,
  linkedin: `https://www.linkedin.com/company/music-eternal/`,
  youtube: `https://www.youtube.com/@MusicEternal_AU`,
  tiktok: `https://tiktok.com/@musiceternal.life`,
};
